export default {
  data() {
    return {
      phones: [],
      newPhone: null
    }
  },
  computed: {
    phone_params() {
      var all = this.phones
      if (this.newPhone) {
        all.push(this.newPhone)
      }
      return {
        phones_attributes: all
      }
    },
  },
  methods: {
    //// this code is required when the data comes from Vuex
    setPhones(phones) {
      this.phones = []
      for (var i = 0; i < phones.length; i++) {
        var ph = {}
        for(var p in phones[i]) {
          ph[p] = phones[i][p]
        }
        this.phones.push(ph)
      }
      this.newPhone = null
    },
    updatePhoneAttr(params) {
      if (params.idx == null) {
        if (this.newPhone == null) {
          this.newPhone = {}
        }
        this.newPhone[params.attr] = params.value
      } else {
        this.phones[params.idx][params.attr] = params.value
      }
    }
  }
}
